<script>
import { app } from '@/main'

import { APPLICABILITY_OPTIONS } from '../constants'

const TRANSLATION = {
  btnBack: {
    toList: app.$t('notifications.mediation-plan:audit.modal.to-list'),
    toDetails: app.$t('notifications.mediation-plan:audit.modal.to-details')
  },

  dataTable: {
    header: {
      user: app.$t('notifications.mediation-plan:audit.modal-details.datatable.header.1'),
      error: app.$t('notifications.mediation-plan:audit.modal-details.datatable.header.3'),
      validations: app.$t('notifications.mediation-plan:audit.modal-details.datatable.header.4')
    }
  }
}

const TAB_ROUTES_TYPES = {
  details: 'tabDetails',
  validations: 'tabValidations'
}

export default {
  name: 'AuditModalDetailList',

  components: {
    Action: () => import('@/components/general/Action'),
    DataTable: () => import('@/components/general/Datatable'),
    Modal: () => import('@/components/general/Modal')
  },

  props: {
    isActiveModal: {
      type: Boolean,
      default: false
    },

    log: {
      type: Object,
      default: () => {}
    }
  },

  data () {
    return {
      tab: TAB_ROUTES_TYPES.details,
      btnBackText: '',
      validationList: []
    }
  },

  computed: {
    getApplicabilityText () {
      return APPLICABILITY_OPTIONS[this.log.applicability]
    }
  },

  watch: {
    tab: {
      immediate: true,
      handler (value) {
        const TAB_TYPES = {
          tabDetails: TRANSLATION.btnBack.toList,
          tabValidations: TRANSLATION.btnBack.toDetails
        }

        this.btnBackText = TAB_TYPES[value]
      }
    }
  },

  created () {
    this.setup()
  },

  methods: {
    setup () {
      this.TRANSLATION = TRANSLATION
      this.TAB_ROUTES_TYPES = TAB_ROUTES_TYPES
    },

    handleClose () {
      const TAB_TYPES = {
        tabDetails: this.closeModal,
        tabValidations: this.toTabDetails
      }

      TAB_TYPES[this.tab]()
    },

    hasLogList (logList) {
      return logList.length > 0
    },

    setValidationList (validationList) {
      this.validationList = validationList
    },

    closeModal () {
      this.$emit('close-modal')
    },

    toTabDetails () {
      this.tab = TAB_ROUTES_TYPES.details
    },

    openValidationListTab (validationList) {
      this.tab = TAB_ROUTES_TYPES.validations
      this.setValidationList(validationList)
    },

    closeValidationListTab () {
      this.tab = TAB_ROUTES_TYPES.details
    }
  }
}
</script>

<template>
  <Modal
    class="detail-list"
    :active="isActiveModal"
    :cancel-label="btnBackText"
    @close="handleClose"
  >
    <div class="main-content-inner center">
      <template v-if="tab === TAB_ROUTES_TYPES.details">
        <DataTable :items="log.details">
          <template slot="thead">
            <tr>
              <th
                class="th"
                width="300"
              >
                {{ TRANSLATION.dataTable.header.user }}
              </th>
              <th
                class="th"
                width="150"
              >
                {{ getApplicabilityText }}
              </th>
              <th
                class="th"
                width="150"
              >
                {{ TRANSLATION.dataTable.header.error }}
              </th>
              <th
                class="th text-center"
                width="100"
              >
                {{ TRANSLATION.dataTable.header.validations }}
              </th>
            </tr>
          </template>

          <template
            slot="tbody"
            slot-scope="props"
          >
            <tr>
              <td class="td">
                <span class="td-text">{{ props.item.user.name }}</span>
              </td>

              <td class="td">
                <span class="td-text">{{ props.item.entity.name }}</span>
              </td>

              <td class="td">
                <span class="td-text">{{ props.item.message }}</span>
              </td>

              <td
                class="td td-actions text-center"
                width="40"
              >
                <Action
                  class="details"
                  :disabled="!hasLogList(props.item.validations)"
                  :style="{ opacity: hasLogList(props.item.validations) ? '1' : '0.25' }"
                  type="button"
                  icon="poll"
                  @click="openValidationListTab(props.item.validations)"
                />
              </td>
            </tr>
          </template>
        </DataTable>
      </template>

      <template v-if="tab === TAB_ROUTES_TYPES.validations">
        <DataTable
          :items="validationList"
          class="table-validation"
        >
          <template slot="thead">
            <tr>
              <th class="th text-center">
                {{ TRANSLATION.dataTable.header.validations }}
              </th>
            </tr>
          </template>

          <template
            slot="tbody"
            slot-scope="props"
          >
            <tr>
              <td class="td">
                <span class="td-text">{{ props.item }}</span>
              </td>
            </tr>
          </template>
        </DataTable>
      </template>
    </div>
  </Modal>
</template>

<style lang="scss" src="../assets/scss/AuditModalDetailList.scss" scoped></style>
